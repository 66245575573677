import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/mswsn/projects/vcs-childhood-elearning-q2-2020/src/components/layout.tsx";
import { Navigation } from "../components/navigation";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Avslutning`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABXEps6JWU/wD/xAAaEAADAQADAAAAAAAAAAAAAAABAgMRACEx/9oACAEBAAEFAnoBRSir7y+Gz5oPX//EABcRAAMBAAAAAAAAAAAAAAAAAAIQEhH/2gAIAQMBAT8BqRxf/8QAFxEAAwEAAAAAAAAAAAAAAAAAARAREv/aAAgBAgEBPwGaNX//xAAeEAAABQUBAAAAAAAAAAAAAAAAARARMQISISJRkf/aAAgBAQAGPwKw46CqLLo/o1hP/8QAGxABAAICAwAAAAAAAAAAAAAAAQARIUExcZH/2gAIAQEAAT8hUkFMx0KrqPBvyKqMNQC6UNQ6z//aAAwDAQACAAMAAAAQUO//xAAYEQADAQEAAAAAAAAAAAAAAAAAATEhQf/aAAgBAwEBPxBZKpeH/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBITH/2gAIAQIBAT8Qa1g5P//EABsQAAIDAAMAAAAAAAAAAAAAAAERACExQVGh/9oACAEBAAE/EHTjYbBTyGQpsiO7gACEGItgYDs8+QJhUUEpxU5//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Lyktor",
          "title": "Lyktor",
          "src": "/static/8e7fa9b85bf7d8a129706edbca40ec15/e5166/lanterns.jpg",
          "srcSet": ["/static/8e7fa9b85bf7d8a129706edbca40ec15/f93b5/lanterns.jpg 300w", "/static/8e7fa9b85bf7d8a129706edbca40ec15/b4294/lanterns.jpg 600w", "/static/8e7fa9b85bf7d8a129706edbca40ec15/e5166/lanterns.jpg 1200w", "/static/8e7fa9b85bf7d8a129706edbca40ec15/d9c39/lanterns.jpg 1800w", "/static/8e7fa9b85bf7d8a129706edbca40ec15/df51d/lanterns.jpg 2400w", "/static/8e7fa9b85bf7d8a129706edbca40ec15/93719/lanterns.jpg 4000w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h4>{`Tack för att du har lärt dig några av grunderna till att vara en viktig vuxen!`}</h4>
    <p><strong parentName="p">{`Bra jobbat!`}</strong>{` Du har nu tagit dig igenom utbildningsmaterialet och klarat Childhoods kunskapstest. Detta har gett dig flera grundläggande verktyg för att kunna vara en viktig vuxen för barn i din omgivning.`}</p>
    <p>{`Vi hoppas att du bär med dig dessa kunskaper och sprider dem vidare. Glöm inte att det spelar ingen roll var i landet man bor, övergrepp på internet sker överallt. Och det är allas vårt ansvar som vuxna att skapa en trygg miljö för barnen, online som offline.`}</p>
    <p>{`Slutligen vill vi rikta ett stort tack till dig för att du tog dig tiden att ta del av denna utbildning. Vi hoppas att den kan fungera som en påminnelse om att det runtomkring dig finns barn som kan behöva bli sedda av dig.`}</p>
    <Navigation previous={{
      chapter: "Kapitel 4",
      title: "Kunskapstest",
      link: "kapitel-4"
    }} mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      